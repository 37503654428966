import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import Loading from "components/Loading";

class Confirmacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        {this.props.loading ?
          <Row>
            <Col className="d-flex justify-content-center">
              <Loading />
            </Col>
          </Row>
          : <>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>Nombre</label><br />
                <span>{this.props.wizardData['Datos'] ? this.props.wizardData['Datos'].values.firstName : '-'}</span>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Apellido</label><br />
                <span>{this.props.wizardData['Datos'] ? this.props.wizardData['Datos'].values.lastName : '-'}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>DNI o Pasaporte</label><br />
                <span>{this.props.wizardData['Datos'] ? this.props.wizardData['Datos'].values.passport : '-'}</span>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Teléfono celular</label><br />
                <span>{this.props.wizardData['Datos'] ? this.props.wizardData['Datos'].values.phone : '-'}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>Email</label><br />
                <span>{this.props.wizardData['Datos'] ? this.props.wizardData['Datos'].values.email : '-'}</span>
              </div>
            </Col>
          </Row>
        </>
      }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading } = state.Home;
  return {
    loading
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(Confirmacion);

