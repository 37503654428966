import React, { createRef } from "react";
// Redux
import { connect } from "react-redux";

import { Container, Row, Col } from "reactstrap";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import { find, filter } from "lodash";

import logo from "../assets/images/logo.png";

import Emitter from "helpers/emitter_helper";

import Wizard from "components/Wizard";

import Tramite from "./Steps/Tramite";
import Disponibilidad from "./Steps/Disponibilidad";
import Sucursal from "./Steps/Sucursal";
import SucursalAlt from "./Steps/SucursalAlt";
import Fecha from "./Steps/Fecha";
import FechaAlt from "./Steps/FechaAlt";
import Datos from "./Steps/Datos";
import Confirmacion from "./Steps/Confirmacion";
import ReactGA from 'react-ga';


import { getBranches, getSchedules, createAppointment } from "store/actions";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      steps: [
        { stepName: "Tipo de análisis", component: Tramite, stepIcon: "mdi mdi-clipboard-text" },
        { stepName: "Disponibilidad", component: Disponibilidad, stepIcon: "mdi mdi-card-search-outline", hidePrevious: true },
        { stepName: "Datos", component: Datos, stepIcon: "mdi mdi-account-circle" },
        { stepName: "Confirmación", component: Confirmacion, stepIcon: "mdi mdi-check-circle" }
      ],
      breadcrumb: {
        tramite: {
          title: '',
          icon: 'mdi mdi-clipboard-text'
        },
        sucursal: {
          title: '',
          icon: 'mdi mdi-domain'
        },
        fecha: {
          title: '',
          icon: 'mdi mdi-calendar'
        }
      },
      success_msg: false,
      error_msg: false
    };
  }

  componentDidMount() {
    var stepsSucursal = [
      { stepName: "Tipo de análisis", component: Tramite, stepIcon: "mdi mdi-clipboard-text" },
      { stepName: "Disponibilidad", component: Disponibilidad, stepIcon: "mdi mdi-card-search-outline", hidePrevious: true },
      { stepName: "Sedes", component: Sucursal, stepIcon: "mdi mdi-domain" },
      { stepName: "Fecha y hora", component: Fecha, stepIcon: "mdi mdi-calendar" },
      { stepName: "Datos", component: Datos, stepIcon: "mdi mdi-account-circle" },
      { stepName: "Confirmación", component: Confirmacion, stepIcon: "mdi mdi-check-circle" }
    ];

    var stepsFecha = [
      { stepName: "Tipo de análisis", component: Tramite, stepIcon: "mdi mdi-clipboard-text" },
      { stepName: "Disponibilidad", component: Disponibilidad, stepIcon: "mdi mdi-card-search-outline", hidePrevious: true },
      { stepName: "Fecha y hora", component: FechaAlt, stepIcon: "mdi mdi-calendar" },
      { stepName: "Sedes", component: SucursalAlt, stepIcon: "mdi mdi-domain" },
      { stepName: "Datos", component: Datos, stepIcon: "mdi mdi-account-circle" },
      { stepName: "Confirmación", component: Confirmacion, stepIcon: "mdi mdi-check-circle" }
    ];

    Emitter.on('TYPE_UPDATED', (value) => {
      if (parseInt(value) == 1) {
        this.setState({ steps: stepsSucursal, type: parseInt(value) });
      } else {
        this.setState({ steps: stepsFecha, type: parseInt(value) });
      }
    });

    this.props.getBranches();
    this.props.getSchedules(null, (result) => this.onGetSchedules(result));
  }

  componentWillUnmount() {
    Emitter.off('TYPE_UPDATED');
  }

  finish(allStates) {
    ReactGA.event({
      evnet: 'click',
      category: 'Cita',
      action: 'Cita confirmada'
    });
    const tramites = filter(this.props.schedules, (s) => {
      return s.name == allStates['Tipo de análisis'].scheduleName;
    }); 
    const tramite = filter(tramites, (s) => {
      return find(s.branches, { id: allStates['Sedes'].branch });
    })[0];

    let payload = {
      branch: {
        id: allStates['Sedes'].branch
      },
      schedule: {
        id: tramite.id
      },
      startAt: allStates['Fecha y hora'].startAt,
      endAt: allStates['Fecha y hora'].endAt,
      customer: {
        firstName: allStates['Datos'].values.firstName,
        lastName: allStates['Datos'].values.lastName,
        passport: allStates['Datos'].values.passport,
        phone: allStates['Datos'].values.phone,
        email: allStates['Datos'].values.email,
      }
    }
    
    this.props.createAppointment(payload, (result) => this.onAppointmentCreate(result));
  }

  onAppointmentCreate(result) {
    if (result) {
      this.setState({ success_msg: true });
    } else {
      this.setState({ error_msg: true });
    }
  }

  onGetSchedules(result) {
    if (result) {
      if (this.props.match.params.tramite) {
        setTimeout(() => {
          Emitter.emit('TRAMITE_FROM_URL', this.props.match.params.tramite);
        }, 1500);
      }
    }
  }

  render() {
    return (
      <Container>
        <Row className="justify-content-center mt-3 mb-3">
          <Col>
            <div className="text-center">
              <img src={logo} alt="CentraLab" className="img-fluid" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Wizard
              title="Solicitud de turnos"
              steps={this.state.steps}
              breadcrumb={this.state.breadcrumb}
              headerTextCenter
              validate
              color="primary"
              finishButtonText="Finalizar"
              finishButtonClick={this.finish.bind(this)}
              finishButtonClasses="btn-success"
              previousButtonText="Anterior"
              nextButtonText="Siguiente"
              nextButtonClasses="btn-primary"
              type={this.state.type}
            />
            {this.state.success_msg ? (
              <SweetAlert
                title="¡Gracias por elegirnos!"
                success
                confirmBtnBsStyle="success"
                confirmBtnText="Aceptar"
                onConfirm={() => {
                  if (process.env.REACT_APP_THANK_YOU_PAGE_URL) {
                    window.top.location = process.env.REACT_APP_THANK_YOU_PAGE_URL;
                  } else {
                    window.location.reload();
                  }
                }}
              >
                Te enviamos un correo electrónico con todos los datos de tu turno.
              </SweetAlert>
            ) : null}
            {this.state.error_msg ? (
              <SweetAlert
                title="Error!"
                error
                confirmBtnBsStyle="danger"
                confirmBtnText="Aceptar"
                onConfirm={() => this.setState({ error_msg: false })}
              >
                {this.props.error.apiMessage == 'API_APPOINTMENTS_CREATE_MAX_NUMBER_OF_APPOINTMENTS_PER_CUSTOMER_PER_DAY_REACHED' ? 'Se alcanzó el límite máximo establecido de citas por cliente por día.' : 'Ha ocurrido un error. Por favor, intente nuevamente'}
              </SweetAlert>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { schedules, error } = state.Home;
  return {
    schedules, error
  };
}

export default connect(mapStateToProps, {
  getBranches,
  getSchedules,
  createAppointment
})(Home);
