import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import Flatpickr from "react-flatpickr";

import moment from "moment";

import { filter, find, sortBy } from "lodash";

import Loading from "components/Loading";

import Emitter from "helpers/emitter_helper";

import { getDates } from "store/actions";

class Fecha extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validated: false,
      branchId: null,
      hours: [],
      date: null,
      scheduleName: null,
      hour: null,
      startAt: null,
      endAt: null,
      key: 1
    };
  }

  componentDidMount() {
    Emitter.on('BRANCH_SELECTED', (obj) => {
      this.setState({ branchId: obj.branch });

      obj.schedules.forEach((s) => {
        this.props.getDates(s.name, s.id, obj.branch, moment().format('YYYY-MM-DDTHH:mm:ssZZ'));
      });
    });

    Emitter.on('RESET', (value) => {
      this.setState({ key: this.state.key + 1 });
    });
    Emitter.on('SET_SCHEDULE', (value) => {
      if (value) {
        this.setState({ scheduleName: value });
      }
    });

  }

  componentWillUnmount() {
    Emitter.off('BRANCH_SELECTED');
    Emitter.off('RESET');
    Emitter.off('SET_SCHEDULE');
  }

  //eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props !== prevProps && !this.props.enabledDates.length) {
      this.setState({ hours: [], date: null, hour: null, startAt: null, endAt: null });
    }
  }

  isValidated() {
    return this.state.startAt && this.state.endAt;
  }

  handleMonthChange(selectedDates, dateStr, instance) {
    if (!this.props.loading) {
      this.props.getDates(this.state.scheduleName, this.props.wizardData['Sedes'].schedule, this.state.branchId, moment(instance.currentYear + '-' + (instance.currentMonth + 1) + '-01', 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ssZZ'));
    }

    this.setState({ hours: [], startAt: null, endAt: null, date: null, hour: null });
  }

  handleYearChange(selectedDates, dateStr, instance) {
    if (!this.props.loading) {
      this.props.getDates(this.state.scheduleName, this.props.wizardData['Sedes'].schedule, this.state.branchId, moment(instance.currentYear + '-' + (instance.currentMonth + 1) + '-01', 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ssZZ'));
    }

    this.setState({ hours: [], startAt: null, endAt: null, date: null, hour: null });
  }

  selectDate(date) {
    let selected = moment(date.date[0]).format('YYYY-MM-DD');
    let filtered = filter(this.props.dates, { formattedDate: selected });
    
    let hours = sortBy(filtered.filter((h) => { return h.availability > 0 }).map((f) => {
      f.formattedHour = moment(f.zonedStartDate).format('HH:mm');

      return f;
    }), 'formattedHour');

    Emitter.emit('BREADCRUMB_DATE', moment(date.date[0]).format('DD/MM/YYYY'));
    
    this.setState({ hours, date, hour: null });
  }

  setHour(event) {
    let hour = find(this.state.hours, { formattedHour: event.target.value });
    
    this.setState({ 
      startAt: moment(hour.zonedStartDate).format('YYYY-MM-DDTHH:mm:ssZZ'), 
      endAt: moment(hour.zonedStartDate).add(hour.defaultDuration, 'minutes').format('YYYY-MM-DDTHH:mm:ssZZ'),
      hour: event.target.value
    });

    Emitter.emit('BREADCRUMB_DATE', moment(hour.zonedStartDate).format('DD/MM/YYYY') + ' ' + moment(hour.zonedStartDate).format('HH:mm'));
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col className="pl-0" md={{ size: 3, offset: 1 }} xs={{ size: 12 }}>
            <Flatpickr
              key={this.state.key}
              className="form-control d-block"
              options={{
                locale: Spanish,
                inline: true,
                enable: this.props.enabledDates
              }}
              onChange={date => {
                this.selectDate({ date });
              }}
              onMonthChange={(selectedDates, dateStr, instance) => this.handleMonthChange(selectedDates, dateStr, instance) }
              onYearChange={(selectedDates, dateStr, instance) => this.handleYearChange(selectedDates, dateStr, instance)}
            />
          </Col>
          <Col className="hour-selection" xs={12} md={{ size: 5, offset: 1 }}>
            {this.props.loading ?
              <Loading />
              :
              <Row>
              {
                this.state.hours.map((hour, index) => (
                  <Col className="px-xs-0" key={index} md={4} xs={12}>
                    <div className="custom-radio">
                      <input id={'date-' + index} name="hours" type="radio" checked={this.state.hour == hour.formattedHour} value={hour.formattedHour} onChange={this.setHour.bind(this)} />
                      <label htmlFor={'date-' + index}>{hour.formattedHour}</label>
                    </div>
                  </Col>
                ))
              }
              {
                ((!this.state.hours.length && this.state.date) || !this.props.enabledDates.length) && (
                  <Col className="px-xs-0">
                    No hay horarios disponibles.
                  </Col>
                )
              }
              </Row>
            }
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading, enabledDates, dates, error } = state.Home;
  return {
    loading, enabledDates, dates, error
  };
}

export default connect(mapStateToProps, {
  getDates
}, null, { forwardRef: true })(Fecha);


